.label {
  font-weight: normal;
  color: #333;
}

.value {
  color: #555;
}

.label, .value, .secondary-value {
  font-weight: normal;
}

.label.default {
  font-size: 14px;
}

.value.default {
  font-size: 18px;
}

.label.small {
  font-size: 12px;
}

.value.small {
  font-size: 16px;
}

.label.large {
  font-size: 14px;
  font-weight: bold;
}

.value.large {
  font-size: 24px;
  font-weight: bold;
}

.label-value-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secondary-value.default {
  font-size: 14px;
  color: #777;
}

.secondary-value.small {
  font-size: 12px;
  color: #888;
}

.secondary-value.large {
  font-size: 16px;
  color: #555;
}
